template
<template>
  <div class="not-found" style="height: 100vh; position: relative">
    <div class="background-image" :style="{ backgroundImage: `url(${require('../assets/404.png')})` }"></div>
    <div class="content" style="position: absolute; top: 10px; bottom: 20%; left: 20px; right: 0; display: flex; align-items: center; justify-content: center;">
      <div class="actions">
        <el-button type="danger" size="medium" @click="goToHome">点我返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NotFound",
  setup() {
    const router = useRouter();
    const goToHome = () => {
      router.push("/home");
    };
    return {
      goToHome,
    };
  },
  methods: {
    goToHome() {
      this.router.push("/home");
    },
  },
  created() {
    this.router = this.$router; // 如果在Vue 2中，需要这样注入$router
  },
};
</script>

<style scoped>
.not-found {
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  bottom: 0px;
  background-size: auto;
  z-index: -1; /* 确保背景图在内容层之下 */
}

.content {
  position: relative; /* 用于覆盖背景图 */
  z-index: 1; /* 提升内容层的层级，使其不被背景图遮挡 */
}

.actions {
  text-align: center;
}
</style>
